import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function PrivacyPolicy() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>PRIVACY POLICY</h3>
              <p>
                We at 99GENS LLC (together with our affiliates, “99GENS", “we”, “our”
                or “us”) respect your privacy and are strongly committed to keeping
                secure any information we obtain from you or about you. This Privacy
                Policy describes our practices with respect to Personal Information we
                collect from or about you when you access 99GENS and its affiliates’
                websites (collectively, the “Site”), or use 99GENS and its affiliates’
                products and services, including application programming interfaces,
                associated software, tools, developer services, data and documentation
                (collectively, the “Services”).
              </p>
              <h3>1. Personal Information</h3>
              <p>
                The collection of Personal Information, which alone or in combination
                with other information in our possession could be used to identify
                you, is necessary for the provision of our Services. We collect
                Personal Information in the following ways:
              </p>
              <p>
                If you create an account to use our Services or communicate with us,
                we may collect Personal Information such as your name, contact
                information, and the contents of any messages you send ("Communication
                Information").
              </p>
              <ul>
                <li>
                  Personal Information You Provide: If you create an account to use
                  our Services or communicate with us, we may collect Personal
                  Information such as your name, contact information, and the contents
                  of any messages you send ("Communication Information").
                </li>

                <li>
                  Personal Information We Collect Through Our Social Media Pages: We
                  have pages on social media sites like Instagram, Facebook, Medium,
                  Twitter, YouTube and LinkedIn (“Social Media Pages”). When you
                  interact with our Social Media Pages, we will collect Personal
                  Information that you elect to provide to us, such as your contact
                  details (“Social Information”). In addition, the companies that host
                  our Social Media Pages may provide us with aggregate information and
                  analytics regarding the use of our Social Media Pages.
                </li>

                <li>
                  Personal Information We Receive Automatically From Your Use of the
                  Services: When you visit, use, and interact with the Services, we
                  may receive Technical Information about your visit, use, or
                  interactions, including Log Data such as your Internet Protocol
                  address, browser type and settings, the date and time of your
                  request, and how you interacted with the Site, Usage Data such as
                  the types of content that you view or engage with, the features you
                  use and the actions you take, as well as your time zone, country,
                  the dates and times of access, user agent and version, type of
                  computer or mobile device, computer connection, IP address, and
                  Device Information such as the name of the device, operating system,
                  and browser you are using.
                </li>

                <li style={{display: 'inline-block'}}>
                  Cookies and Analytics: We use cookies to operate and administer our
                  Site and Services, and improve your experience on it. We may also
                  use a variety of online analytics products that use cookies to help
                  us analyze how users use the Site and Services and enhance your
                  experience when you use the Site and Services. Please visit
                  <a href="https://www.allaboutcookies.org/" style={{whiteSpace: "nowrap"}}>{' All About Cookies'}</a>.
                </li>

                <li>
                  Online Tracking and Do Not Track Signals: We and our third-party
                  service providers may use cookies or other tracking technologies to
                  collect information about your browsing activities over time and
                  across different websites following your use of the Site. Our Site
                  currently does not respond to “Do Not Track” (“DNT”) signals, and
                  operates as described in this Privacy Policy regardless of the
                  receipt of a DNT signal.
                </li>
              </ul>
              <p>
                We do not allow the collection of Personal Information for any illegal
                activity, child sexual abuse material, generation of hateful,
                harassing, or violent content, activity that has high risk of physical
                or economic harm, fraudulent or deceptive activity, adult content,
                adult industries, and dating apps, political campaigning or lobbying
                (without express written consent), activity that violates people’s
                privacy, or use of copyrighted, trademarked, or identity, image or
                likeness of public profiles. We reserve the right to update our
                policies from time to time without notice to you.
              </p>
              <h3>2. How we use Personal Information Collected</h3>
              <p>We may use Personal Information for the following purposes:</p>
              <p>
                To provide, administer, maintain, improve and/or analyze the Services;
              </p>

              <ul>
                <li>
                  To conduct research, which may remain internal or may be shared with
                  third parties, published or made generally available;
                </li>
                <li>To communicate with you;</li>
                <li>To develop new programs and services;</li>
                <li>
                  To prevent fraud, criminal activity, or misuses of our Services, and
                  to ensure the security of our IT systems, architecture, and
                  networks; and
                </li>
                <li>
                  To comply with legal obligations and legal process and to protect
                  our rights, privacy, safety, or property, and/or that of our
                  affiliates, you, or other third parties.
                </li>
              </ul>
              <p>
                We may aggregate Personal Information and use the aggregated
                information to analyze the effectiveness of our Services, to improve
                and add features to our Services, to conduct research (which may
                remain internal or may be shared with third parties, published or made
                generally available) and for other similar purposes. In addition, we
                may analyze the general behavior and characteristics of users of our
                Services and share aggregated information like general user statistics
                with third parties, publish such aggregated information or make such
                aggregated information generally available. We may collect aggregated
                information through the Services, through cookies, and through other
                means described in this Privacy Policy. It should be noted that this
                list of purposes is not exhaustive and we may use Personal Information
                for other purposes that are compatible with the context in which it
                was collected, or with your consent.
              </p>
              <h3>3. Sharing and Disclosure of Personal Information</h3>
              <p>
                In certain circumstances, we may share your Personal Information with
                third parties without further notice to you, unless required by law,
                including without limitation in the situations described below:
              </p>
              <p>
                Vendors and Service Providers: To assist us in meeting business
                operations needs and to perform certain services and functions, we may
                share Personal Information with vendors and service providers,
                including providers of Print On Demand services, merchant services,
                hosting services, cloud services, and other information technology
                services providers, event management services, email communication
                software and email newsletter services, advertising and marketing
                services, and web analytics services. Pursuant to our instructions,
                these parties will access, process, or store Personal Information in
                the course of performing their duties to us.
              </p>
              <p>
                Business Transfers: If we are involved in strategic transactions,
                reorganization, bankruptcy, receivership, or transition of service to
                another provider (collectively a “Transaction”), your Personal
                Information and other information may be shared in the diligence
                process with counterparties and others assisting with the Transaction
                and transferred to a successor or affiliate as part of that
                Transaction along with other assets.
              </p>
              <p>
                Legal Requirements: We may disclose your Personal Information if
                required to do so by law or in the good faith belief that such action
                is necessary to (i) comply with a legal obligation, including to meet
                national security or law enforcement requirements, (ii) protect and
                defend our rights or property, (iii) prevent fraud, (iv) act in urgent
                circumstances to protect the personal safety of users of the Services,
                or the public, or (v) protect against legal liability.
              </p>
              <p>
                Affiliates: We may share Personal Information with our affiliates,
                meaning an entity that controls, is controlled by, or is under common
                control with 99GENS LLC Our affiliates may use the Personal
                Information we share in a manner consistent with this Privacy Policy.
              </p>
              <p>
                Other Users: Certain actions you take may be visible to other users of
                the Service. Please note that this list is not exhaustive and we may
                share your Personal Information for other purposes, which we will
                inform you about at the time of collection.
              </p>
              <h3>4. Contact Us to Update your Information</h3>
              <p>
                You have the right to contact us at any time to request an update or
                removal of your personal information, in accordance with applicable
                laws and regulations. We will make reasonable efforts to respond to
                your request in a timely manner and will take appropriate steps to
                fulfill your request, subject to any legal or contractual obligations
                that may prevent us from doing so. Please note that certain personal
                information may be required to provide you with access to our
                Services, and the removal of such information may result in the
                inability to access or use certain features of our Services.
              </p>
              <p>
                Please contact us to request the removal of your personal data or to
                address any questions or concerns that have not been already addressed
                in this Privacy Policy.
              </p>
              <p>By mail:</p>
              <p>
                99GENS LLC<br />651 N. BROAD ST., SUITE 201<br />MIDDLETOWN DE 19709
              </p>
              <p>
                or, by electronic mail:
                <a href="mailto:privacy@99gens.com">{` privacy@99gens.com`}</a>
              </p>
              <h3>5. California Privacy Rights</h3>
              <p>
                The following disclosures are to provide supplementary information
                regarding: (1) the categories of Personal Information, as defined
                above, that we collect; (2) the source(s) of the Personal Information;
                (3) how we use each category of Personal Information; and (4) how we
                disclose Personal Information. These disclosures are not intended to
                restrict our capacity to utilize or reveal information as described
                previously.
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Category of Personal Information</th>
                      <th>Sources of Personal Information</th>
                      <th>Use of Personal Information</th>
                      <th>Disclosure of Personal Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>Social Information</strong></td>
                      <td>
                        We may collect Social Information from you when you interact
                        with our Social Media Pages.
                      </td>
                      <td>
                        We may use Social Information to perform analytics and to
                        communicate with you.
                      </td>
                      <td>We may disclose Social Information to our affiliates.</td>
                    </tr>
                    <tr>
                      <td>Communication Information</td>
                      <td>We collect Communication Information directly from you.</td>
                      <td>
                        We use Communication Information for providing our Services
                        and responding to you.
                      </td>
                      <td>
                        We disclose Communication Information to our affiliates and
                        communication services providers.
                      </td>
                    </tr>
                    <tr>
                      <td>Technical Information</td>
                      <td>We collect Technical Information from you.</td>
                      <td>
                        We use Technical Information for analytics and in some cases,
                        for moderation and prevention of fraud and malicious activity
                        by users of our Services.
                      </td>
                      <td>
                        We disclose Technical Information to our affiliates and
                        analytics provider(s).
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                California residents have certain privacy rights with respect to their
                Personal Information collected by us, subject to applicable exceptions
                and as provided for by law. These rights include the right to know
                what Personal Information we have collected, used, and disclosed, the
                right to request deletion of their Personal Information, and the right
                to be free from discrimination relating to the exercise of their
                privacy rights.
              </p>
              <p>
                We do not sell and will not sell California residents' Personal
                Information. To exercise their privacy rights, California residents
                may contact us by emailing
                <a href="mailto:support@99gens.com">{` support@99gens.com`}</a>. To protect
                their Personal Information, we may require California residents to
                verify their credentials before submitting a request to know or delete
                their Personal Information. If the California resident does not have
                an account with us, or if we suspect fraudulent or malicious activity,
                we may ask for additional Personal Information and proof of residency
                for verification. We will not provide or delete Personal Information
                if we cannot verify their identity.
              </p>
              <p>
                California residents may also authorize an agent to submit a request
                to know or delete their Personal Information on their behalf. If they
                do so, the agent must provide signed written permission to act on
                their behalf, and the California resident may be required to
                independently verify their identity and provide proof of their
                residency to us.
              </p>
              <h3>6. Children</h3>
              <p>
                Our Service is not intended for individuals under the age of 13.
                99GENS does not knowingly collect Personal Information from
                individuals under the age of 13. If you believe that a child under the
                age of 13 has provided Personal Information to 99GENS through the
                Service, please contact us immediately at
                <a href="mailto:support@99gens.com">{` support@99gens.com`}</a>. We will
                make reasonable efforts to promptly delete such information from our
                databases.
              </p>

              <h3>7. Links to Websites</h3>
              <p>
                The Service may contain hyperlinks to websites that are not owned or
                controlled by 99GENS, including social media services (“Third Party
                Sites”). The privacy policies and terms of service of the Third Party
                Sites will govern the information that you share with them, rather
                than this Privacy Policy. The inclusion of these hyperlinks does not
                suggest that we endorse or have reviewed these Third Party Sites. For
                information on the privacy practices and policies of the Third Party
                Sites, please contact them directly.
              </p>

              <h3>8. Security</h3>
              <p>
                The use of the Service is solely at your own risk. We employ
                commercially reasonable technical, administrative, and organizational
                measures to safeguard Personal Information, both online and offline,
                against loss, misuse, unauthorized access, disclosure, alteration, or
                destruction. However, please be advised that no Internet or e-mail
                transmission can ever be fully secure or error-free. Specifically,
                e-mails sent to or from us may not be secure. Accordingly, you should
                exercise particular caution when deciding which information to send to
                us via the Service or e-mail. Furthermore, we shall not be held
                accountable for any attempts to circumvent any privacy settings or
                security measures incorporated on the Service or third-party websites.
              </p>

              <h3>9. International Users</h3>
              <p>
                By utilizing our Service, you acknowledge and consent to the transfer
                of your Personal Information from your location to our facilities and
                servers situated in the United States.
              </p>

              <h3>10. Your Choices</h3>
              <p>
                If you elect not to disclose certain Personal Information that is
                necessary to access certain functionalities of our Service, you may be
                unable to utilize such functionalities.
              </p>

              <h3>11. Changes to The Privacy Policy</h3>
              <p>
                We reserve the right to modify this Privacy Policy at any time and in
                our sole discretion. We will provide notice of any material changes by
                posting an updated Privacy Policy on the Service or by other means as
                required by applicable law. Your continued use of the Service or
                provision of Personal Information after the effective date of the
                revised Privacy Policy constitutes your acceptance of the terms of the
                revised Privacy Policy.
              </p>

              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
